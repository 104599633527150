import * as React from "react";
import { useInterfaceManager } from "./InterfaceManager";
import ComponentRenderer from "./toucan/src/interfaces";
import { actions } from "./toucan/src/store";

const App = () => {
  const currentInterface = useInterfaceManager();

  React.useEffect(() => {
    actions().requestPart();
  }, []);

  React.useEffect(() => {
    document.body.className = currentInterface || "";
    document.body.scrollTop = 0;
  }, [currentInterface]);


  return <ComponentRenderer interface={currentInterface} />;
};

export default App;
